import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import UserContext from '../contexts/UserContext';
import { createProgram } from '../services/programs_service';
import Notification from '../components/notification';
import PackageSection from '../components/package-section';
import SessionSection from '../components/session-section';
import ProgramSection from '../components/program-section';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    margin: '1rem',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    margin: '2rem',
  },
}));

export const StyledForm = styled('form')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const CURRENCY_OPTIONS = ['Rub', 'Dollar', 'Euro', 'BEL Rub'];
const TYPE_OPTIONS = ['Offline', 'Online', 'Hybrid'];

export default function AddProgramPage() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [isUploading, setIsUploading] = useState(false);
  const [snackData, setSnackData] = useState({
    isOpen: false,
    isSuccess: true,
  });
  const sessionsState = useState([]);
  const packagesState = useState([]);
  const INITIAL_PROGRAM_DETAILS = {
    name: '',
    type: TYPE_OPTIONS[0],
    group: '',
    language: 'English',
    description: '',
    category_id: '',
    sub_category_id: '',
    coaches_id: user?.userRoles.includes('COACH') ? [user?.id] : [],
    currency: CURRENCY_OPTIONS[0],
    tags: [],
  };
  const programDetailsState = useState(INITIAL_PROGRAM_DETAILS);

  const [programFormError, setProgramFormError] = useState(null);
  const [sessions] = sessionsState;
  const [programDetails] = programDetailsState;

  function attachNonFormFields(data) {
    data.created_at = Timestamp.now();
    data.updated_at = Timestamp.now();
    data.members = [];
    data.numberOfParticipants = 0;
    data.rating = 5.0;
    return data;
  }

  function checkDataValidity() {
    const data = programDetails;
    if (!data.name) return t('Enter the program name');
    if (!data.category_id) return t('Select a category');
    if (!data.coaches_id || data.coaches_id.length === 0) return t('Select one coach at least');
    if (!data.language) return t('Enter the program language');
    if (!data.description) return t('Enter the program description');
    if (!data.currency) return t('Enter the program currency');
    return null;
  }

  const submitForm = async () => {
    const _error = checkDataValidity();
    if (_error) {
      setProgramFormError(_error);
      return;
    }

    const payload = attachNonFormFields(programDetails);

    setIsUploading(true);

    setProgramFormError(null);
    createProgram(payload, sessions, packagesState[0])
      .then(() => {
        sessionsState[1]([]);
        packagesState[1]([]);
        programDetailsState[1](INITIAL_PROGRAM_DETAILS);
      })
      .catch((error) => {
        console.log(error);
        setSnackData((prev) => ({ ...prev, isSuccess: false }));
      })
      .finally(() => {
        setIsUploading(false);
        setSnackData((prev) => ({ ...prev, isOpen: true }));
      });
  };

  const handleCloseSnack = () => {
    setSnackData((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      <Helmet>
        <title> {t('Create Program')}</title>
      </Helmet>
      <StyledRoot>
        <Stack
          gap="4rem"
          justifyContent="center"
          alignItems="flex-start"
          direction={{ xs: 'column', sm: 'row' }}
          width="100%"
        >
          {/* Left Section */}
          <ProgramSection payloadState={programDetailsState} error={programFormError} />
          
          {/* Right Section */}
          <Stack width={{ xs: '100%', sm: 'auto' }}>
            <Stack paddingBottom="2rem" alignItems="flex-end" width="100%" display={{ xs: 'none', sm: 'flex' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                onClick={submitForm}
                loading={isUploading}
                disabled={isUploading}
              >
                {t('create')}
              </LoadingButton>
            </Stack>

            {/* Right Top Section */}
            <SessionSection currency={programDetails.currency} sessionsState={sessionsState} />

            {/* Right Bottom Section */}
            <PackageSection currency={programDetails.currency} sessions={sessions} packagesState={packagesState} />

            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              onClick={submitForm}
              loading={isUploading}
              disabled={isUploading}
              sx={{ display: { xs: 'initial', sm: 'none' }, marginTop: 4 }}
              fullWidth
            >
              {t('create')}
            </LoadingButton>
          </Stack>
        </Stack>

        <Notification
          open={snackData.isOpen}
          success={snackData.isSuccess}
          message={t(
            snackData.isSuccess ? 'Program Successfully Created' : 'Error creating program, please try again later'
          )}
          onClose={handleCloseSnack}
        />
      </StyledRoot>
    </>
  );
}
